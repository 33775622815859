<template>
  <div id="vehiclesPresent-tab">
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <el-input
              placeholder="请输入车牌号"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getList(1)"
              ></el-button>
            </el-input>
          </li>
        </ul>
      </div>
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column label="车牌匹配1" align="center" show-overflow-tooltip>
          <template #default="scope">
            <span :class="{ differentPlateNum: !scope.row.isSamePlateNum }">{{
              scope.row.plateNum
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="车牌匹配2" align="center" show-overflow-tooltip>
          <template #default="scope">
            <span :class="{ differentPlateNum: !scope.row.isSamePlateNum }">{{
              scope.row.plateExtNum
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="车辆类型1" align="center" show-overflow-tooltip>
          <template #default="scope">
            <span :class="{ differentPlateNum: !scope.row.isSamePlateNum }">{{
              scope.row.vehicleLeaseType
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="车辆类型2" align="center" show-overflow-tooltip>
          <template #default="scope">
            <span :class="{ differentPlateNum: !scope.row.isSamePlateNum }">{{
              scope.row.vehicleExtLeaseType
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="arrivalTime" label="入场时间" align="center">
        </el-table-column>
        <el-table-column prop="channelName" label="入场设备" align="center">
        </el-table-column>
        <el-table-column prop="parkingTime" label="停车时长" align="center">
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="prev, pager, next"
          background
          :total="total"
          :page-size="pageSize"
          :current-page.sync="page"
          @current-change="getList"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      loading: false,
      total: 0,
      page: 1,
      pageSize: 10,
      filtrate: {
        keyword: "",
      },
    };
  },
  computed: {
    info() {
      return this.$store.state.GangTing.info;
    },
    parkingLotId() {
      return this.$store.state.GangTing.parkingLotId;
    },
  },
  watch: {
    info() {
      this.getList();
    },
    parkingLotId: {
      handler(id) {
        if (id) {
          this.page = 1;
          this.getList();
        }
      },
      immediate: true,
    },
  },
  methods: {
    forcedOut(parkingRecordId) {
      this.$confirm("此操作将强制出场, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let res = await this.$http.post(`/parking/forced/out`, {
          parkingRecordId,
        });
        if (res.code === 0) {
          this.$message.success("操作成功");
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    async getList(page = this.page) {
      try {
        this.loading = true;
        let res = await this.$http.post("/parking/list/vehicle", {
          pageNum: page,
          pageSize: this.pageSize,
          parkingLotId: this.parkingLotId,
          ...this.filtrate,
        });
        if (res.code === 0) {
          for (let item of res.data.list) {
            item.arrivalTime = this.$moment(item.arrivalTime).format(
              "yyyy-MM-DD HH:mm:ss"
            );
            item.plateNum = item.plateNum ?? "无牌车";
            if (!item.plateNum || !item.plateExtNum) {
              item.isSamePlateNum = true;
            } else {
              item.isSamePlateNum = item.plateNum === item.plateExtNum;
            }
          }
          this.tableData = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
#vehiclesPresent-tab {
}
</style>
